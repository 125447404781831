import { Base64 } from 'js-base64';
import Model from '../model/resume';
import { viewsModelToModels as carViewsModelToModels } from './card';
import { viewsModelToModels as billingCycleViewsModelToModels } from './billingCycle';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    let icon = '';
    let text = '';

    switch (view.type) {
      case 'AUTO':
        icon = 'assets/icons/auto.png';
        text = 'Seguro de Veículo';
        break;
      case 'HEALTH':
        icon = 'assets/icons/health.png';
        text = 'Seguro de Saúde';
        break;
      case 'LIFE':
        icon = 'assets/icons/life.png';
        text = 'Seguro de Vida';
        break;
      case 'HOME':
        icon = 'assets/icons/home.png';
        text = 'Seguro Residencial';
        break;
      case 'BUSINESS':
        icon = 'assets/icons/business.png';
        text = 'Seguro Empresarial';
        break;
      case 'CONDOMINIUM':
        icon = 'assets/icons/condominium.png';
        text = 'Seguro de Condomínio';
        break;
      case 'ELEMENTARY_BRANCH':
        icon = 'assets/icons/elementary_branch.png';
        text = 'Seguro de Ramos Elementares';
        break;
      default:
        icon = 'assets/icons/home.png';
        text = 'Seguro Residencial';
        break;
    }

    model.BillingCycles = [];
    model.BillingCycles = billingCycleViewsModelToModels(view.billingCycles);
    model.CompanyName = view.company && view.company.fantasyName;
    model.CompanyLogo = view.company && view.company.logo;
    model.CustomerId = view.customer.id;
    model.Description = view.description;
    model.TextType = text;
    model.Type = view.type;
    model.IconType = icon;
    model.FirstName = view.customer.firstName;
    model.LastName = view.customer.fullName;
    model.FullName = view.customer.fullName;
    model.Email = view.customer.email;
    model.Document = view.customer.document;
    model.ProposalNumber = view.policyNumber;
    model.TotalPrize = view.amount || 0;
    model.BalancePay = view.amountPayable || view.amount || 0;
    model.InstallmentCount = view.checkoutPreferences.installmentCount || 0;
    model.InstallmentValue = view.checkoutPreferences.installmentValue || 0;
    model.ButtonConfirmBackground = view.checkoutPreferences.layout.colors.buttonConfirmBackground || '#000000';
    model.ButtonConfirmText = view.checkoutPreferences.layout.colors.buttonConfirmText || '#ffffff';
    model.TopLineBackground = view.checkoutPreferences.layout.colors.topLineBackground || '#000000';
    model.Cards = [];
    model.Cards = carViewsModelToModels(view.cards);
    model.PublicKey = view.security.encryptionKey && Base64.decode(view.security.encryptionKey);
    model.Status = view.status;
    model.Cars = [];
    model.Cars = view.informationPolicy && view.informationPolicy.cars;
    model.Phones = [];
    model.Phones = (view.voucher && view.voucher.authOptions && view.voucher.authOptions.sms) || [];
    model.Remaining = view && view.remaining
     && { Amount: view.remaining.amount, Installments: view.remaining.installments };
    model.VoucherStatus = (view.voucher && view.voucher.status) || '';
    model.ProviderName = (view.voucher && view.voucher.provider && view.voucher.provider.toUpperCase()) || '';
    model.NotChangePaymentMethod = view.changePaymentMethod === false;
    model.ShowParcelInfo = view.showParcelInfo;
    model.RecurringUnitInfo = view.recurringUnitInfo;
    model.RecurringUnitDescription = view.recurringUnitDescription;

    const points = view.checkoutPreferences.paymentInstruments.filter((x) => x.type === 'LOYALTY_POINTS');

    model.VoucherValueId = points.length > 0 ? points[0].id : 0;
    model.VoucherOptionId = points.length > 0 ? points[0].optionId : 0;
    model.VoucherValue = points.length > 0 ? points[0].points : 0;
    model.OnlyCreditCard = model.VoucherValue === 0;

    return model;
  } catch (error) {
    throw new Error(`ResumeMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.resumes.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`ResumeMapper - viewsModelToModels = ${error}`);
  }
};

export const modelToFilterDetail = (params) => {
  try {
    if (!params) return null;

    const view = params.token;

    return view;
  } catch (error) {
    throw new Error(`ResumeMapper - ModelToFilter = ${error}`);
  }
};
